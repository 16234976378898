import { KeyboardEventHandler, useCallback, useMemo, useRef } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, PopoverOrigin, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";

import {
  MhcLocationStat,
  MhcLocationStatFragment,
  MhcStatIdentifierFragment,
  MhcTarget,
  MhcTargetFragment
} from "graphqlApi/types";

import { KPI_VALUE_FLEX, KPI_VALUE_WIDTH } from "../configuration";
import { buildStatements, determineProgressIcons } from "./util";
import { visuallyHidden } from "@mui/utils";
import { formatValueByUnit } from "common/util/formatHelpers";
import { getValueOptionsFromSi } from "common/util/formatHelpers/statIdentifierHelpers";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import TargetIcon from "../../Icons/TargetIcon";
import { KpiIcons } from "../KpiIcons";
import { KpiProps } from "../";
import { TargetPopover } from "./TargetPopover";

export const POPOVER_ORIGIN: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left"
};

export interface KpiTargetProps {
  currentValue: number;
  dateRange?: Date[] | null;
  hideLongStatement?: boolean;
  isImproving?: boolean;
  kpiFillsContainer?: KpiProps["fillContainer"];
  locationName?: string | null;
  name?: string;
  percentageChange?: string | null;
  statIdentifier: Pick<
    MhcStatIdentifierFragment,
    | "id"
    | "improvement"
    | "precision"
    | "subtitle"
    | "unit"
    | "statType"
    | "name"
    | "isPercent"
    | "ageAdjusted"
  >;
  statSentence?: MhcLocationStat["sentence"];
  target?: MhcTarget | MhcTargetFragment | null;
  targetMet?: boolean;
  locationStat?: MhcLocationStatFragment;
}

export const KpiTarget: React.FC<KpiTargetProps> = ({
  hideLongStatement = false,
  kpiFillsContainer,
  percentageChange,
  target,
  locationStat,
  statIdentifier,
  ...targetProps
}) => {
  const domId = useMemo(() => `target-${statIdentifier.id}`, [statIdentifier.id]);
  const ref = useRef<HTMLDivElement | null>(null);
  const isMobile = useIsMobile();

  const popupState = usePopupState({
    variant: "popover",
    popupId: domId
  });

  let progressIcons = null;
  const { shortStatement, longStatement: _longStatement } = useMemo(
    () => buildStatements({ target, statIdentifier, ...targetProps }),
    [target, targetProps, statIdentifier]
  );

  progressIcons = useMemo(
    () => determineProgressIcons(shortStatement || percentageChange || ""),
    [percentageChange, shortStatement]
  );

  const longStatement = locationStat?.sentence ?? _longStatement;

  const formattedTargetValue = useMemo(() => {
    if (!target) return undefined;
    return formatValueByUnit({
      value: target.value,
      ...getValueOptionsFromSi(statIdentifier)
    });
  }, [target, statIdentifier]);

  const handleKeyboardDown = useCallback<KeyboardEventHandler<HTMLTableRowElement>>(
    (e) => {
      if (e.key !== "Enter") return;
      if (isNil(ref)) return;
      popupState.open(ref.current);
    },
    [popupState]
  );

  return (
    <Stack
      spacing={1}
      onClick={() =>
        sendGaUserInteractionEvent({
          category: "KPI",
          action: "Target click",
          label: statIdentifier?.name ?? "",
          ui_location: window.location.pathname
        })
      }
      onKeyDown={handleKeyboardDown}
    >
      {formattedTargetValue && (
        <Box
          {...bindTrigger(popupState)}
          display="flex"
          width="100%"
          tabIndex={0}
          sx={{
            position: "relative",
            minHeight: 40,
            backgroundColor: "brand.light",
            borderRadius: "4px",
            px: 1,
            py: 0.5,
            mt: 1.5,
            mb: 0.5,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "brand.light",
            flexWrap: {
              xs: "wrap",
              lg: kpiFillsContainer ? undefined : "wrap",
              cursor: "pointer",
              "&:hover, &:focus": {
                borderColor: "brand.main"
              }
            },
            gap: { xs: 0.75, lg: 0 }
          }}
          aria-haspopup="true"
          ref={ref}
        >
          <Stack
            direction="row"
            flexGrow={1}
            gap={{ xs: 0, md: 2 }}
            width={"100%"}
            sx={{
              minWidth: { lg: kpiFillsContainer ? KPI_VALUE_WIDTH : undefined }
            }}
          >
            {isMobile && (
              <Stack gap={2} sx={{ justifyContent: "center" }}>
                <TargetIcon fontSize="inherit" />
                {progressIcons?.length > 0 && <KpiIcons icons={progressIcons} />}
              </Stack>
            )}
            <Stack direction={{ xs: "column", md: "row" }} sx={{ my: "auto" }} flexGrow={1} gap={2}>
              {target && (
                <Stack direction="row" flexGrow={0} gap={1} alignItems="baseline">
                  {!isMobile && <TargetIcon fontSize="inherit" sx={{ my: "auto" }} />}
                  <Typography
                    component="p"
                    minWidth={{ xs: "80px", md: "auto" }}
                    variant="body2"
                    fontWeight="700"
                    sx={{ mt: isMobile ? "auto" : undefined }}
                  >
                    Target
                  </Typography>
                  <Stack display="flex" flexGrow={1}>
                    <Typography
                      component="p"
                      width={{ xs: "auto", md: "max-content" }}
                      variant="body2"
                    >
                      {formattedTargetValue}
                      {statIdentifier?.subtitle && (
                        <Typography ml={0.5} component="span" variant="body3">
                          {statIdentifier?.subtitle}
                        </Typography>
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              <Stack direction="row" flexGrow={0} gap={1}>
                {!isMobile && progressIcons?.length > 0 ? (
                  <Typography fontSize="20px" component="span">
                    <KpiIcons icons={progressIcons} />
                  </Typography>
                ) : (
                  !kpiFillsContainer && <Stack width="16px" />
                )}
                <Typography
                  component="p"
                  minWidth={{ xs: "80px", md: "auto" }}
                  variant="body2"
                  fontWeight="700"
                  sx={{ mb: isMobile ? "auto" : undefined }}
                >
                  Progress
                </Typography>
                <Stack display="flex" gap={0.5} alignItems="baseline" flexGrow={1}>
                  <Typography
                    component="p"
                    width={{ xs: "auto", md: "max-content" }}
                    variant="body2"
                  >
                    {shortStatement}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{ ml: "auto", my: "auto", justifyContent: "center", alignContent: "center" }}
            >
              <IconButton sx={{ height: "min-content" }}>
                <span style={visuallyHidden}>
                  {popupState.isOpen ? "Expand less icon" : "Expand more icon"}
                </span>
                {popupState.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      )}

      {target && (
        <TargetPopover
          popupState={popupState}
          tableProps={{
            target: target,
            formattedValue: formattedTargetValue,
            subtitle: statIdentifier.subtitle ?? "",
            progress: shortStatement
          }}
          uiLocation="KPI"
        />
      )}
      {!isNil(longStatement) && !hideLongStatement && (
        <Typography
          data-testid={`${statIdentifier.id}__long-statement`}
          aria-label={`${longStatement} ${locationStat?.needs_attention ?? ""}`}
          tab-index={0}
        >
          {longStatement} {/* TODO: */}
          {/* We need to refactor the following, we should only use one or the other in the future */}
          {/* I struggled a little bit to find out why the sentence was duplicate. */}
          {/* The best would probably be to use a single sentence coming from the KPI prop builder */}
          {(locationStat?.needs_attention !== longStatement && locationStat?.needs_attention) ?? ""}
        </Typography>
      )}
    </Stack>
  );
};
